import React, { Component, Fragment } from 'react';
import Appbar from './shared/Components/Appbar/Appbar'
import PublicRoutes from './PublicRoutes/PublicRoutes'
import PrivateRoutes from './PrivateRoutes/PrivateRoutes'
import withAuthentication from './HOCs/withAuthentication'
import AuthUserContext from './shared/Components/AuthUserContext'
import Footer from './shared/Components/Footer'
// import './App.css'
// import './index.css'


class App extends Component {

  render() {
    return (
      <Fragment>
        <Appbar/>
        <PublicRoutes />
        <AuthUserContext.Consumer>
          {authUser => authUser &&
            <PrivateRoutes />
          }
        </AuthUserContext.Consumer>
        <Footer />
      </Fragment>

    );
  }
}

export default withAuthentication(App);
