import React from 'react'
import {
  Container,
  Navbar,
  Row,
  Col
} from 'reactstrap'
import './footer.css'

const Footer = () => (
  <Container fluid className='footer-container' tag='footer'>
    <Row>
      <Col md={3} style={{marginLeft: '20px'}}>
        <h3>Follow Us!</h3>
        <ul className='list-unstyled'>
          <li>
            <i className="fab fa-facebook-square"></i>
            <a href="https://www.facebook.com/Schoolsuccessproject" target="_blank" rel="noopener noreferrer"> Facebook</a>
          </li>
          <li>
            <i className="fab fa-twitter-square"></i>
            <a href="https://twitter.com/SchoolSuccessP1" target="_blank" rel="noopener noreferrer"> Twitter</a>
          </li>
          {/* <li>YouTube</li> */}
        </ul>
      </Col>
      <Col>
        <Row>
          <Col md={6}>
            <h3>Contact Us!</h3>
            <h5>Mailing Address</h5>
            <address>
              <strong>COLLEGE OF SOCIAL WORK</strong>
              <div>395 SOUTH 1500 EAST #109</div>
              <div>SALT LAKE CITY, UT 84112</div>
            </address>
          </Col>
          <Col md={6}>
            <h5>Email</h5>
            <a href='mailto:schoolsuccessproject@gmail.com' style={{ color: '#fff' }}>schoolsuccessproject@gmail.com</a>
            {/* <h5 className='mt-2'>Phone</h5>
              <a href='tel:801-333-1111' style={{ color: '#fff' }}>
              801-333-1111
            </a> */}
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>


)

export default Footer