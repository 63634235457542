import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import { db as firebaseDb } from '../../firebase/firebase';
import SchoolMap from '../../shared/Components/SchoolMap'
import StarsModal from './StarsModal'
import FormModal from '../../shared/Components/FormModal'
import './home.css'

class Home extends Component {
  state = {
    modal: false,
    formModal: false
  }

  componentDidMount() {
    firebaseDb.ref('aboutUs').once('value', snapshot => {
      const aboutUsData = snapshot.val()
      this.setState({
        about: aboutUsData.about,
        mainPageText: aboutUsData.mainPageText,
        mission: aboutUsData.mission
      })
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  toggleFormModal = () => {
    this.setState({
      formModal: !this.state.formModal
    })
  }

  handleSuccess = (reset) => {
    if(reset) {
      this.setState({
        success: false
      })
    }
    this.setState({
      success: true
    })
  }

  render() {
    return (
      <Container fluid>
        <Row className='home__header-container'>
          <Col md={{ size: 10, offset: 1 }} style={{zIndex:100}}>
            <h2 className='home__welcome-header-text text-center'>Grand Challenges School Success Project</h2>
            <p className='home__welcome-text text-center'>
              {this.state.mainPageText}
            </p>
          </Col>


        </Row>
          {/* <img src={children} alt='Children' className='home-img'/> */}
        <Row>
          <Col lg={12} >
            <Row >
              <Col className='home__join-us-container text-center' >
                <span style={{zIndex:100}}className='home__join-us-header'>Want to be a part of the School Success Project?</span>
                <Row>
                  <Col className='text-center' style={{ padding: '20px' }}>
                    <Button size='lg' className='home__join-us-button' onClick={this.toggleFormModal}>Join Us!</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

        </Row>
        <Row >

          <Col lg={{ size: 10, offset: 1 }}>
            <div className='text-center mt-4 mb-2'>
              <h3>School Discipline Tracker</h3>
              <span className='home__map-instructions'>Use the map to find your school district!</span>
            </div>

            <div className='mb-3' style={{ boxShadow: '0 2px 6px 0 hsla(0,0%,0%, 0.2)' }}>

              <div className='d-flex' style={{ padding: '10px', borderTop: '6px solid hsl(357, 80%, 30%)' }}>
                <span
                  style={{ color: '#007AA1' }}
                  onClick={this.toggle}
                  className='home__modal-btn'>
                  What is STARS?
                  </span>

                <span style={{ color: '#007AA1' }} className='ml-auto'>
                  Hover over the pins to find STARS and reports.
                  </span>

              </div>

              <SchoolMap />

            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '100%', background: '#183B82', borderTop: '15px solid #273170' }}>
            <div className='home__mission-title-container'>
              <h2 className='text-center home__mission-title'>Our Mission</h2>
            </div>
            {this.state.mission !== '' &&
              <Row className='mt-3'>
                <Col>
                  <p className='home__mission-text text-center'>{this.state.mission}</p>
                </Col>
              </Row>
            }
          </Col>
        </Row>
        <StarsModal isOpen={this.state.modal} toggle={this.toggle} />

        <FormModal
          isOpen={this.state.formModal}
          toggle={this.toggleFormModal}
          checkSuccess={this.handleSuccess}
          success={this.state.success}
        />


      </Container>

    )
  }
}

export default Home