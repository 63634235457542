import React from 'react'
import {
  Modal, ModalBody, ModalHeader,
  Col, Row,
} from 'reactstrap'
import ModalStars from './ModalStars'

const StarsModal = ({isOpen, toggle}) =>
  <Modal isOpen={isOpen} toggle={toggle} style={{maxWidth: '100%'}}>
    <ModalHeader>What is STARS?</ModalHeader>
    <ModalBody>
      <Row>
        <Col>
          <p>STARS stands for Suspension/Expulsion Tracking And Rating System: a method of rating disproportionality in a particular school system and rating approaches to reduce disproportionality.</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <strong style={{color: '#3f3f3f'}}>Disproportionality Index</strong>
          <ModalStars rating={1} starText={'relative risk ratio of 1.8 or more (Extreme Challenges)'}/>
          <ModalStars rating={1.5} starText={'relative risk ratio of 1.7 (Need significant improvement)'}/>
          <ModalStars rating={2} starText={'relative risk ratio of 1.6 (Poor)'}/>
          <ModalStars rating={2.5} starText={'relative risk ratio of 1.5 (Unacceptable)'}/>
          <ModalStars rating={3} starText={'relative risk ratio of 1.4 (Less than acceptable)'}/>
          <ModalStars rating={3.5} starText={'relative risk ratio of 1.3 (Borderline)'}/>
          <ModalStars rating={4} starText={'relative risk ratio of 1.2 (Good)'}/>
          <ModalStars rating={4.5} starText={'relative risk ratio of 1.1 (Very good)'}/>
          <ModalStars rating={5} starText={'relative risk ratio of 1.0 or less (Excellent)'}/>
        </Col>

        <Col md={6} sm={12}>
          <strong style={{color: '#3f3f3f'}}>Progress Index</strong>
          <ModalStars rating={1} starText={'less than 0.50 change from the original risk ratio (Little to no change)'}/>
          <ModalStars rating={2} starText={'0.50 - 0.99 change from the original risk ratio (Low progress)'}/>
          <ModalStars rating={3} starText={'Change of 1.00 - 1.49 from the original risk ratio (Good)'}/>
          <ModalStars rating={4} starText={'Change of 1.50 - 1.99 from the original risk ratio (Very Good)'}/>
          <ModalStars rating={5} starText={'Change of 2.00 or greater from the original risk ratio (Excellent)'}/>

        </Col>
      </Row>
    </ModalBody>
  </Modal>

export default StarsModal