import React, { Component, Fragment } from 'react'
import {
  Row, Col, Table, Button, Alert,
} from 'reactstrap'
import moment from 'moment'
import { db as firebaseDb } from '../../firebase/firebase';
import { db } from '../../firebase'

class JoinUsRequest extends Component {
  state = {
    joinUsData: [],
    archivedView: false
  }

  componentDidMount() {
    firebaseDb.ref('joinUsContacts').on('value', snapshot => {
        const joinUsData = snapshot.val()
          this.setState({
            joinUsData: joinUsData
          })
    })
  }

  renderNewRequests = () =>{
    this.setState({
      joinUsData: [],
      archivedView:false
    })
    firebaseDb.ref('joinUsContacts').on('value', snapshot => {

        const joinUsData = snapshot.val()
        this.setState({
          joinUsData: joinUsData
        })

    })
  }

  markViewed = (key) => {

    db.markJoinUsViewed(key)
      .catch((error) => this.setState({error}))
  }

  renderArchived = () => {
    firebaseDb.ref('readJoinUsContacts').on('value', snapshot => {

      if(snapshot.val() !== null) {
        const joinUsData = snapshot.val()
        this.setState({
          archivedView: true,
          joinUsData: joinUsData
        })
      }
    })
  }

  render() {

    return (
      <Fragment>
        <Row className='mt-3'>
          <Col>
            <h1>Join Us Requests</h1>
            <div>View all requests to join the School Success Project</div>
          </Col>
          <Col>
            <div className='d-flex justify-content-end' >
              {!this.state.archivedView ?
                <Button color='info' onClick={() => this.renderArchived()}><i className="fa fa-archive"></i> Archived Requests</Button>

                :
                <Button color='primary' onClick={() => this.renderNewRequests()}><i className="fa fa-star-of-life"></i> View New Requests</Button>
              }

            </div>
          </Col>
        </Row>

        {this.state.archivedView &&
          <Row>
            <Col className='text-center' style={{background: '#BADA55'}}>
              <strong >ARCHIVED REQUESTS</strong>
            </Col>
          </Row>
        }
        <Table>
          {this.state.error && <Alert>{this.state.error}</Alert>}
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Job Title</th>
              <th>Institution</th>
              <th>School District</th>
              <th>Participation Date</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {this.state.joinUsData !== null && Object.keys(this.state.joinUsData).map(key => {
              const requestData = this.state.joinUsData[key]
              return (
                <tr key={key}>
                  <td>
                    {this.state.archivedView ?
                      <Button color='link' className='text-danger' onClick={() => firebaseDb.ref(`readJoinUsContacts/${key}`).remove()}><i className="fa fa-trash-alt"></i></Button>
                      :
                      <Button color='link' onClick={()=>this.markViewed(key)}><i className="fa fa-eye-slash"></i></Button>
                    }
                  </td>
                  <td>{requestData.name}</td>
                  <td>{requestData.phone}</td>
                  <td><a href={`mailto:${requestData.email}`}>{requestData.email}</a></td>
                  <td>{requestData.jobTitle}</td>
                  <td>{requestData.institution}</td>
                  <td>{requestData.schoolDistrict}</td>
                  <td>{requestData.participationDate && moment(requestData.participationDate).format('MM/DD/YYYY')}</td>
                  <td>{requestData.comment}</td>
                </tr>
              )}
            )}
          </tbody>
        </Table>
      </Fragment>


    )
  }
}

export default JoinUsRequest