import React, { Component, Fragment } from 'react'
import { withFormik } from 'formik'
import { Col, Row, Form, Button, Alert } from 'reactstrap'
import BootstrapInput from '../../shared/Components/BootstrapInput'
import { db } from '../../firebase'


class JoinUs extends Component {

  render() {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      errors,
      isSubmitting,

    } = this.props

    // let hasSubmitted
    // if(submitted ){
    //   hasSubmitted = submitted
    // }
    return (
      <Fragment>
        <Row >
          <Col>
            <div className='text-center'>
              <h1 >Join Us!</h1>
              <h3>Help contribute to the School Success Project</h3>
            </div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={{ size: 8, offset: 2 }}>
            {/* { submitted && <Alert>Submitted Successfully</Alert>} */}
            <Form onSubmit={handleSubmit} >
              <Row>
                <Col md={4}>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.jobTitle}
                    name='jobTitle'
                    label='Job Title'
                    onBlur={handleBlur}
                  />
                </Col>
                <Col md={8}>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.name}
                    name='name'
                    label='Name *'
                    onBlur={handleBlur}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.phone}
                    name='phone'
                    label='Phone Number *'
                    placeholder='(555) 555-5555'
                    onBlur={handleBlur}
                    required
                  />
                </Col>
                <Col md={6}>
                  <BootstrapInput
                    inputType='email'
                    onChange={handleChange}
                    value={values.email}
                    name='email'
                    label='Email *'
                    placeholder='name@institution.com'
                    onBlur={handleBlur}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.institution}
                    name='institution'
                    label='Institution Name'
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.schoolDistrict}
                    name='schoolDistrict'
                    label='School District'
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <BootstrapInput
                    inputType='date'
                    onChange={handleChange}
                    value={values.participationDate}
                    name='participationDate'
                    label='Tentative Participation Date'
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapInput
                    inputType='textarea'
                    onChange={handleChange}
                    value={values.comment}
                    name='comment'
                    label='Comments/Information'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {errors.name &&
                    <div>
                      {errors.name}
                    </div>}
                  <Button type="submit" color='primary' disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>

                </Col>
              </Row>

            </Form>
          </Col>
        </Row>
      </Fragment>

    )
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    jobTitle: '',
    name: '',
    comment: '',
    participationDate: '',
    schoolDistrict: '',
    institution: '',
    email: '',
    phone: ''

  }),
  handleSubmit: (values, { setSubmitting, resetForm, setStatus }) => {
    db.createJoinUs(values).then(() => {
      setSubmitting(false)

      resetForm({
        name: '',
        phone: '',
        email: '',
        institution: '',
        schoolDistrict: '',
        jobTitle: '',
        comment: '',
        participationDate: ''
      })
      // setStatus({
      //   submitted: true
      // })

    })

  }
})(JoinUs)