import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
import {KEYS} from '../environment'
let config
// const config = {
//     apiKey: KEYS.API_KEY,
//     authDomain: KEYS.AUTH_DOMAIN,
//     databaseURL: KEYS.DATABASE_URL,
//     projectId: KEYS.PROJECT_ID,
//     storageBucket: KEYS.STORAGE_BUCKET,
//     messagingSenderId: KEYS.MESSAGING_SENDER_ID
// }

if(process.env.NODE_ENV !== 'production') {
  console.log('dev mode')
  config = {
    apiKey: KEYS.API_KEY,
    authDomain: KEYS.AUTH_DOMAIN,
    databaseURL: KEYS.DATABASE_URL,
    projectId: KEYS.PROJECT_ID,
    storageBucket: KEYS.STORAGE_BUCKET,
    messagingSenderId: KEYS.MESSAGING_SENDER_ID
  }
} else {
  console.log('prod mode')
  config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
  }
}


firebase.initializeApp(config)

const auth = firebase.auth();
const db = firebase.database();
const storage = firebase.storage();
const functions = firebase.functions();

export {
  auth,
  db,
  storage,
  functions
}