import React from 'react'
import {
  Container,
  Col, Row,
} from 'reactstrap'

class NewsAndInformation extends React.Component {

  render() {
    return (
      <Container>
        <h1>News and Info</h1>
      </Container>
    )
  }
}

export default NewsAndInformation