import React from 'react'
import {
  Form, Modal, ModalHeader, ModalFooter,
  ModalBody, Button, Col, Row, Alert
} from 'reactstrap'
import { Formik } from 'formik'
import BootstrapInput from '../../../shared/Components/BootstrapInput'
import {functions} from '../../../firebase/firebase'
import {db} from '../../../firebase'

const FormModal = ({ isOpen, toggle, checkSuccess, success}) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className='text-center'>Join us and help contribute to the School Success Project</ModalHeader>
      {success &&
        <Alert color='success'>Message Sent</Alert>
      }
      <ModalBody>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            email: '',
            institution: '',
            schoolDistrict: '',
            jobTitle: '',
            comment:'',
            participationDate: ''

          }}
          onSubmit={(values, {resetForm}) => {
            db.createJoinUs(values).then(() => {
              checkSuccess()
              resetForm({name: '',
              phone: '',
              email: '',
              institution: '',
              schoolDistrict: '',
              jobTitle: '',
              comment:'',
              participationDate: ''})

              setTimeout(() => {
                checkSuccess('reset')
                toggle()
              }, 3000)
            }).catch((err)=> console.log(err))
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={5}>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.jobTitle}
                    name='jobTitle'
                    label='Job Title'
                  />
                </Col>
                <Col md={7}>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.name}
                    name='name'
                    label='Name *'
                    required
                  />
                </Col>

              </Row>
              <Row>
                <Col>
                  <BootstrapInput
                    inputType='text'
                    onChange={handleChange}
                    value={values.phone}
                    name='phone'
                    label='Phone Number *'
                    placeholder='(555) 555-5555'
                    required
                  />
                </Col>
                <Col>
                  <BootstrapInput
                    inputType='email'
                    onChange={handleChange}
                    value={values.email}
                    name='email'
                    label='Email *'
                    placeholder='name@institution.com'
                    required
                  />
                </Col>
              </Row>


              <BootstrapInput
                inputType='text'
                onChange={handleChange}
                value={values.institution}
                name='institution'
                label='Institution Name'
              />
              <BootstrapInput
                inputType='text'
                onChange={handleChange}
                value={values.schoolDistrict}
                name='schoolDistrict'
                label='School District'
              />
              <Row>
                <Col md={7}>
                  <BootstrapInput
                    inputType='date'
                    onChange={handleChange}
                    value={values.participationDate}
                    name='participationDate'
                    label='Tentative Participation Date'
                  />
                </Col>
              </Row>

              <BootstrapInput
                inputType='textarea'
                onChange={handleChange}
                value={values.comment}
                name='comment'
                label='Comments/Information'
              />

              {errors.name &&
                <div>
                  {errors.name}
                </div>}
              <ModalFooter>
                <Button type="submit" color='primary'>Submit</Button>
                <Button color='link' onClick={toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}
export default FormModal