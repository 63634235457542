import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import AuthUserContext from '../shared/Components/AuthUserContext'
import Home from './Home'
import Login from './Login'
import Sponsors from './Sponsors'
import About from './About'
import ResearchReports from './ResearchReports'
import JoinUs from './JoinUs'
import NotFound from '../shared/Components/NotFound'
import NewsAndInformation from '../PublicRoutes/NewsAndInformation'


class PublicRoutes extends Component {

  render(){
    return(
    <Switch>
      <Route exact path='/news' component={NewsAndInformation} />
      <Route exact path='/research' component={ResearchReports} />
      <Route exact path='/about' component={About} />
      <Route exact path='/sponsors' component={Sponsors} />
      <Route exact path='/join' component={JoinUs} />
      <Route exact path='/ssp-admin' component={Login} />
      <Route exact path='/' component={Home} />


    </Switch>

    )
  }
}

export default PublicRoutes