import React, { Component } from 'react'
import { db as firebaseDb } from '../../firebase/firebase';

import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle
} from 'reactstrap'


class Sponsors extends Component {

  state = {
    sponsorData: [],

  }
  componentDidMount(){
    firebaseDb.ref('sponsorData').on('value', snapshot => {
      const sponsorData = snapshot.val()
      const sponsorDataArr = []

      for(var data in sponsorData){
        sponsorDataArr.push({[data]: sponsorData[data]})
      }
      this.setState({
        sponsorData: sponsorDataArr
      })
    })
  }
  render() {
    return (
      <Container>
        <Row>
          <Col className='d-flex justify-content-center mt-3'>
            <h1>Our Sponsors</h1>
          </Col>
        </Row>

        <Row >
          {this.state.sponsorData.map( (data, i) => {
            const dataKey = data[Object.keys(data)]
            return(
              <Col md={3} className='mt-4' key={i}>
                <Card>
                  <CardImg top width="100%" src={dataKey.sponsorLogo} />
                  <CardBody>
                    <CardTitle>
                      {dataKey.sponsorName}
                    </CardTitle>
                    {dataKey.sponsorWebsiteLink &&
                      <CardSubtitle><small><a href={dataKey.sponsorWebsiteLink} target='_blank'  rel="noopener noreferrer">View Site</a></small></CardSubtitle>
                    }
                    {dataKey.sponsorDescription &&
                      <CardText>{dataKey.sponsorDescription}</CardText>
                    }
                  </CardBody>
                </Card>
              </Col>
            )
          })}
      </Row>

      </Container>
    )
  }
}

export default Sponsors
