import React from 'react'
import StarRatings from 'react-star-ratings'


const ModalStars = ({rating, starText}) =>
  <div className='mb-2' style={{lineHeight: '10px'}}>
    <StarRatings
      rating={rating}
      starRatedColor='gold'
      numberOfStars={5}
      starDimension='15px'
      starSpacing='4px'
    />
    {starText ? starText : (
      <React.Fragment>
        {' '}
        <span style={{color:'#7F7F7E'}}>= relative risk ratio of {6-rating} {rating === 5 && 'or less'} {rating === 1 && 'or more'}</span>
      </React.Fragment>
    )}
  </div>


export default ModalStars