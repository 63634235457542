import React from 'react'
import {Table, Button} from 'reactstrap'
import { db as firebaseDb } from '../../../firebase/firebase';
import { db } from '../../../firebase'



class MapDataList extends React.Component{
  state = {
    mapData: []
  }

  componentDidMount(){
    firebaseDb.ref('mapData').on('value', (snapshot)=> {
      const mapData =snapshot.val()
      const mapDataArr = []
      for(var data in mapData){
          mapDataArr.push({[data]: mapData[data].schoolData})
      }
      this.setState({
        mapData: mapDataArr
      })
    })

  }

  removeMarker = (key, fileName) =>{

    db.deleteMapData(key, fileName)
  }
  render() {
    return(
      <div style={{maxHeight: '560px', overflow: 'auto'}}>
        <Table>
        <thead>
          <tr>
            <th>Location</th>
            <th>Disproportionality Index</th>
            <th>Progress Index</th>
            <th>STAR Report</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.mapData.map( (data, i) => {
            const dataKey = data[Object.keys(data)]
            return(
              <tr key={i} id={Object.keys(data)}>
                <td>{dataKey.address}</td>
                <td>{dataKey.disproportionalityIndex}</td>
                <td>{dataKey.progressIndex}</td>
                <td>{dataKey.fileLink &&
                  <a href={dataKey.fileLink} id={dataKey.fileName} target='_blank' rel="noopener noreferrer">report</a>
                }</td>
                <td>
                  <Button
                    color='link'
                    onClick={() =>this.removeMarker(Object.keys(data)[0], dataKey.fileName)}
                    >
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </td>
              </tr>
            )
          }
          )}
        </tbody>
      </Table>
      </div>

    )

  }
}

export default MapDataList