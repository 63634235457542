import React, {Component} from 'react'
import {Table, Button} from 'reactstrap'
import { db as firebaseDb } from '../../firebase/firebase';
import { db } from '../../firebase'


class ResearchList extends Component {
  state = {
    researchData: [],

  }
  componentDidMount(){
    firebaseDb.ref('researchData').on('value', snapshot => {
      const researchData = snapshot.val()
      const researchDataArr = []
      for(var data in researchData){
        researchDataArr.push({[data]: researchData[data]})
      }
      this.setState({
        researchData: researchDataArr
      })
    })
  }


  removeResearch = (key, fileName) =>{

    db.deleteResearch(key, fileName)
  }

  render(){
    return(
      <div style={{height: '560px', overflow: 'auto'}}>
        <Table>
          <thead>
            <tr>
              <th>Report</th>
              <th>Research Name</th>
              <th>Description</th>
              <th>Website</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.researchData.map( (data, i)=>{
              const dataKey = data[Object.keys(data)]
              return(
                <tr key={i} id={Object.keys(data)}>
                  <td>
                    {dataKey.researchReport != null &&
                    <a href={dataKey.researchReport} target="_blank"  rel="noopener noreferrer" >
                      View Report
                    </a>
                    }

                  </td>
                  <td>
                    <strong>{dataKey.researchName}</strong>
                  </td>
                  <td>
                    {dataKey.researchDescription.length > 140 ? (
                      <p>{dataKey.researchDescription.substring(0,140)} ...more</p>
                    ) : (
                      <p>{dataKey.researchDescription}</p>
                    )}
                  </td>
                  <td>
                    {dataKey.researchWebsite &&
                      <a target='_blank' rel="noopener noreferrer" href={dataKey.researchWebsite}>View Site</a>
                    }
                  </td>
                  <td>
                    <Button
                      color='link'
                      onClick={() =>this.removeResearch(Object.keys(data)[0], `${dataKey.researchName}__${dataKey.fileName}`)}
                      >
                      <i className="fas fa-trash-alt"></i>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

    )
  }
}

export default ResearchList