import React from 'react'
import {
  Modal, ModalBody,Row, Col, Button
} from 'reactstrap'

const ImageModal = ({isOpen, toggle, altText, image}) => {

  return(
    <Modal isOpen={isOpen} toggle={toggle}  style={{maxWidth: '100%'}}>
        <ModalBody>
          <Row>
            <Col>
            <Button color='link' className='text-danger' style={{fontSize: '1.3rem'}} onClick={toggle}><i class="fa fa-times"></i></Button>
            </Col>
          </Row>
          <img src={image} alt={altText} className='img-fluid' style={{width:'100%'}}/>
        </ModalBody>

    </Modal>
  )
}

export default ImageModal