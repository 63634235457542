import React from 'react'
import {
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'

const Tabs = ({tabNumber, activeTab, children, toggleTab}) =>
  <NavItem>
    <NavLink
      style={{cursor: 'pointer'}}
      className={classnames({active: activeTab === tabNumber})}
      onClick={() => {toggleTab(tabNumber)}}
      >
      {children}
    </NavLink>
  </NavItem>

export default Tabs