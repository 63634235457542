import React, { Component } from 'react'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import { Container } from 'reactstrap'

const initialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: 'A line of text in a paragraph.',
              },
            ],
          },
        ],
      },
    ],
  }
})


function MarkHotKey(options){
  const { type, key } = options

  return {
    onKeyDown(event, editor, next) {
      if (!event.ctrlKey || event.key !== key) return next()

      event.preventDefault()
      editor.toggleMark(type)
    }
  }
}


const plugins = [
  MarkHotKey({type: 'bold', key: 'b'}),
  MarkHotKey({type: 'bold', key: 'b'}),
  MarkHotKey({type: 'code', key: '`'}),
  MarkHotKey({type: 'bold', key: 'b'}),
  MarkHotKey({type: 'italic', key: 'i'}),
  MarkHotKey({type: 'strikethrough', key:'~'}),
  MarkHotKey({type: 'underline', key: 'u'})

]
class NewsAndInformation extends Component {
  state = {
    value: initialValue
  }

  onChange = ({value}) => {
    this.setState({value})
  }

  render() {
    return (
      <Container fluid>
        <span>News and info backend</span>

        <Editor
          plugins={plugins}
          value={this.state.value}
          onChange={this.onChange}
          // onKeyDown={this.onKeyDown}
          // renderNode={this.renderNode}
          renderMark={this.renderMark}
        />
      </Container>
    )
  }


  renderMark = (props, editor, next) => {
    switch (props.mark.type) {
      case 'bold':
        return <strong>{props.children}</strong>
      case 'code':
        return <code>{props.children}</code>
      case 'italic':
        return <em>{props.children}</em>
      case 'strikethrough':
        return <del>{props.children}</del>
      case 'underline':
        return <u>{props.children}</u>
      default:
        return next()
    }
  }
}

export default NewsAndInformation