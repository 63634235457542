import React from 'react';
import { db } from '../firebase';


const getMapData = (Component) =>
  class WithAuthentication extends React.Component {
    state = {
      mapData:[]
    }

    componentDidMount() {
      db.getMapData().then(snapshot => {
        const mapData =snapshot.val()
        const mapDataArr = []
        for(var data in mapData){
            mapDataArr.push({[data]: mapData[data].schoolData})
        }
        this.setState({
          ...this.state,
          mapData: mapDataArr
        })
      })
    }
    render() {
      return (
        <Component {...this.props} mapData={this.state.mapData} />
      );
    }
  }

export default getMapData