/* global window */
import React, {Component} from 'react';
import MapGL, {Marker, Popup, NavigationControl} from 'react-map-gl';
import SchoolMapMarker from './SchoolMapMarker';
import CityInfo from './CityInfo';
import getMapData from '../../HOCs/getMapData'
import {KEYS} from '../../environment'

let TOKEN

if(!process.env.NODE_ENV !== 'production') {
  TOKEN = KEYS.MAP_TOKEN
} else {
  TOKEN = process.env.REACT_APP_MAP_TOKEN
}

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 37.785164,
        longitude: -96,
        zoom: 3.6,
        bearing: 0,
        pitch: 0,
      },
      popupInfo: null
    };
  }

  _updateViewport = (viewport) => {
    this.setState({viewport});
  }

  _renderCityMarker = (city, index) => {
    const markerData = city[Object.keys(city)]
    return (
      <Marker key={Object.keys(city)}
        longitude={markerData.longitude}
        latitude={markerData.latitude} >
        <SchoolMapMarker size={20} onClick={() => this.setState({popupInfo: markerData})} />
      </Marker>
    );
  }

  _renderPopup() {
    const {popupInfo} = this.state;

    return popupInfo && (
      <Popup tipSize={5}
        anchor="top"
        longitude={popupInfo.longitude}
        latitude={popupInfo.latitude}
        onClose={() => this.setState({popupInfo: null})} >
        <CityInfo info={popupInfo} />
      </Popup>
    );
  }

  render() {

    const {viewport} = this.state;
    const { mapData } = this.props

    return (

      <MapGL
        {...viewport}
        width='100%'
        height='580px'
        onViewportChange={this._updateViewport}
        mapboxApiAccessToken={TOKEN}
        mapStyle='mapbox://styles/mapbox/light-v9'
        >
        { mapData.map(this._renderCityMarker) }
        {this._renderPopup()}
        <div className="nav" style={navStyle}>
          <NavigationControl onViewportChange={this._updateViewport} />
        </div>
      </MapGL>


    );
  }

}

export default getMapData(App)