import React, {Component} from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Alert
} from 'reactstrap'
import SponsorList from './SponsorList'
import { storage } from '../../firebase/firebase'
import { db } from '../../firebase/firebase'
import firebase from 'firebase/app'

const storageRef = storage.ref('logos')

////////////////////////
// HTTP String helper //
////////////////////////
function httpStringHelper(url) {
  if(url === '')
    return ''

  if(url.indexOf("http://") === 0 || url.indexOf('https://') === 0){
    return url
  } else {
    return `http://${url}`
  }

}

////////////////
// Component  //
////////////////
class Sponsors extends Component {
  state={
    sponsorName: '',
    sponsorDescription: '',
    sponsorWebsiteLink: '',
    selectedFile: null,
    errorMessage: null,

  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  fileSelectedHandler = event => {

    if(event.target.files[0].size > 1000000){
      this.setState({
        selectedFile: null,
        errorMessage: 'Selected file is bigger than 1MB. Please selected a smaller file.'
      })
    } else {
      this.setState({
        errorMessage: null
      })
    }
    this.setState({
      selectedFile: event.target.files[0]
    })
  }

  submit = () => {
    if (this.state.selectedFile == null) {
      db.ref('sponsorData').push({
        sponsorName: this.state.sponsorName,
        sponsorDescription: this.state.sponsorDescription,
        sponsorWebsiteLink: httpStringHelper(this.state.sponsorWebsiteLink)
      }).then(() => {
        this.setState({
          sponsorName: '',
          sponsorDescription: '',
          sponsorWebsiteLink: '',
          selectedFile: null,
          errorMessage: null,
        })
      })
    }
    else {
      const uploadTask = storageRef.child(`${this.state.sponsorName}__${this.state.selectedFile.name}`).put(this.state.selectedFile)
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      function(snapshot){
        let progress = (parseFloat(snapshot.bytesTransferred) / parseFloat(snapshot.totalByes)) * 100
        console.log(`Upload is ${progress}% done!`)
      },
      function(error){
        switch (error.code) {
          case 'storage/unauthorized':
            this.setState({
              errorMessage: 'Unauthorized'
            })
            break;
          case 'storage/canceled':
          this.setState({
            errorMessage: 'Cancelled'
          })
            break;
          case 'storage/unknown':
          this.setState({
            errorMessage: 'Unknown Error. Error code' + error.code
          })
            break;
          default:
            return null
        }
      }, ()=> {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
          const sponsorData = {
            sponsorName: this.state.sponsorName,
            sponsorDescription: this.state.sponsorDescription,
            sponsorWebsiteLink: httpStringHelper(this.state.sponsorWebsiteLink),
            sponsorLogo: downloadURL
          }

          db.ref('sponsorData').push(sponsorData).then(() => {
            this.setState({
              sponsorName: '',
              sponsorDescription: '',
              sponsorWebsiteLink: '',
              selectedFile: null,
              errorMessage: null
            })
          }, err => {
            this.setState({
              sponsorName: '',
              sponsorDescription: '',
              sponsorWebsiteLink: '',
              selectedFile: null,
              errorMessage: err
            })
          })
        })
      })
    }




  }

  render(){
    return(
      <Container fluid className='mt-3'>
      {this.state.errorMessage &&
        <Alert color="warning">
         {this.state.errorMessage}
        </Alert>
      }
        <Row className='mt-2 mb-2'>
          <Col>
            <h1>Sponsors</h1>
            <p>Here you can add or delete sponsors on the sponsors page</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardTitle>
                  Add Sponsor
                </CardTitle>
                <Form>
                  <FormGroup>
                    <Label>
                      Sponsor Name
                    </Label>
                    <Input
                      type='text'
                      name='sponsorName'
                      onChange={this.onChange}
                      value={this.state.sponsorName}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Sponsor Description (If applicable)
                    </Label>
                    <Input
                      type='textarea'
                      name='sponsorDescription'
                      onChange={this.onChange}
                      value={this.state.sponsorDescription}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Sponsor Website Link (If applicable)
                    </Label>
                    <Input
                      type='input'
                      name='sponsorWebsiteLink'
                      onChange={this.onChange}
                      value={this.state.sponsorWebsiteLink}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Sponsor Logo (If applicable)
                      <small style={{display: 'block'}}>File must not exceed 1MB</small>
                    </Label>
                    <Input
                      type='file'
                      onChange={this.fileSelectedHandler}
                    ></Input>
                  </FormGroup>
                  <Button
                    onClick={this.submit}
                    disabled={this.state.sponsorName === '' || this.state.errorMessage}
                  >Submit</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardTitle>Sponsor List</CardTitle>
                <SponsorList />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
   )
  }
}

export default Sponsors