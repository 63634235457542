//////////
// COMMENT OUT THE KEYS DEPENDING ON WHICH ENVIRONMENT YOU ARE WORLING IN
///////////////////////


// Dev key
// export const KEYS = {
//   "API_KEY": "AIzaSyAesyFFY3jsws3TxCaKJnSlp6t2dlm_dR8",
//   "AUTH_DOMAIN": "school-success-p-1539666378262.firebaseapp.com",
//   "DATABASE_URL": "https://school-success-p-1539666378262.firebaseio.com",
//   "PROJECT_ID": "school-success-p-1539666378262",
//   "STORAGE_BUCKET": "school-success-p-1539666378262.appspot.com",
//   "MESSAGING_SENDER_ID": "966898827819",
//   "MAP_TOKEN": "pk.eyJ1IjoiY2hyaXNoY2hlbmciLCJhIjoiY2ptbzdmbHV0MTFrZTN2cDU5anB6NmdpaSJ9.WEj6j08F35R1r9iKVcbnFg"
// }

// prod key
export const KEYS = {
  "API_KEY": "AIzaSyB5o8BpIweiq9DdShehnLu8mc2JFZYeDUA",
  "AUTH_DOMAIN": "school-success-project.firebaseapp.com",
  "DATABASE_URL": "https://school-success-project.firebaseio.com",
  "PROJECT_ID": "school-success-project",
  "STORAGE_BUCKET": "school-success-project.appspot.com",
  "MESSAGING_SENDER_ID": "174812213494",
  "MAP_TOKEN": "pk.eyJ1IjoiY2hyaXNoY2hlbmciLCJhIjoiY2ptbzdmbHV0MTFrZTN2cDU5anB6NmdpaSJ9.WEj6j08F35R1r9iKVcbnFg"
};