import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import { db as firebaseDb } from '../../firebase/firebase';
import './researchReports.css'

class ResearchReports extends Component {
  state={
    researchData: []
  }
  componentDidMount(){
    firebaseDb.ref('researchData').once('value', snapshot => {
      const researchData = snapshot.val()
      const researchDataArr = []
      for(var data in researchData){
        researchDataArr.push({[data]: researchData[data]})
      }
      this.setState({
        researchData: researchDataArr
      })
    })

  }

  render() {
    const { researchData } = this.state
    return (
      <Container className='mt-4'>
        <Row className='mt-3 mb-3 rr__header'>
            <h3 style={{color: '#128AB7'}}>Resources</h3>
        </Row>
        {researchData.map((data, i) =>{
          const dataKey = data[Object.keys(data)]
          return(
            <Row className='rr__report-section mt-3'>
              <Col>
                <Row >
                    <h4 className='rr__report-name'>{dataKey.researchName}</h4>



                </Row>
                <Row className='mb-2'>
                {dataKey.researchWebsite &&
                    <a href={dataKey.researchWebsite} target='_blank' rel="noopener noreferrer" className='rr__report-link'>Report Website</a>
                  }
                {dataKey.researchReport &&
                    <a href={dataKey.researchReport} target='_blank' rel="noopener noreferrer" className='rr__report-link'>
                     View Report</a>
                  }
                </Row>
                {dataKey.researchDescription &&
                  <Row className='mb-3'>
                    <p>{dataKey.researchDescription}</p>
                  </Row>
                }

              </Col>
            </Row>
          )
        } )}
      </Container>
    )
  }
}

export default ResearchReports