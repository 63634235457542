import React from 'react'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Button
} from 'reactstrap';
import {Link, NavLink} from 'react-router-dom'
import { auth } from '../../../firebase';
import AuthUserContext from '../AuthUserContext'
import logo from '../../images/logo.jpg'
import headerPic from '../../images/success.jpg'
import './appbar.css'

const Appbar = () => {

  return(
    <Navbar expand="md" className='appbar__container'>
    <NavbarBrand tag={Link} to={'/'} >
      <img src={logo} alt="" style={{width: '220px'}}/>
    </NavbarBrand>
      {/* <img src={headerPic} alt="School Success" style={{width:'250px'}} className='img-fluid'/> */}
      <AuthUserContext.Consumer>
        {authUser => authUser ?
          <NavigationAuth /> :
          <NavigationNonAuth />
        }
      </AuthUserContext.Consumer>
  </Navbar>
  )
}

const NavigationAuth = () =>
  <Nav className="ml-auto nav-adjustments" navbar>
    {/* <NavItem>
      <div style={{marginRight:'15px'}}>
        <NavLink activeClassName='appbar__active' to={'/news'} className='appbar__nav-link'>News &amp; Information</NavLink>
      </div>
    </NavItem> */}
    <NavItem>
      <div style={{marginRight:'15px'}}>
        <NavLink activeClassName='appbar__active' to={'/research'} className='appbar__nav-link'>Resources</NavLink>
      </div>
    </NavItem>
    <NavItem>
      <div style={{marginRight:'15px'}}>
        <NavLink activeClassName='appbar__active' to={'/about'} className='appbar__nav-link'>About Us</NavLink>
      </div>
    </NavItem>
    <NavItem>
      <div style={{marginRight:'15px'}}>
        <NavLink activeClassName='appbar__active' to={'/sponsors'} className='appbar__nav-link'>Sponsors</NavLink>
      </div>
    </NavItem>
    <NavItem>
      <div style={{marginRight:'15px'}}>
        <NavLink activeClassName='appbar__active' to={'/join'} className='appbar__nav-link'>Join Us</NavLink>
      </div>
    </NavItem>
    <NavItem>
      <div style={{marginRight:'15px'}}>
        <NavLink activeClassName='appbar__active' to={'/ssp-admin/dashboard'} className='appbar__nav-link'>Admin Dashboard</NavLink>
      </div>
    </NavItem>
    <NavItem>
      <Button color='danger' onClick={() => auth.doSignOut()}>Admin Sign Out</Button>
    </NavItem>
  </Nav>

const NavigationNonAuth = () =>
  <Nav className="ml-auto nav-adjustments" navbar>
    {/* <div style={{marginRight: '15px'}}><NavLink activeClassName='appbar__active' to={'/news'} className='appbar__nav-link'>News &amp; Information</NavLink></div> */}
    <div style={{marginRight: '15px'}}><NavLink activeClassName='appbar__active' to={'/research'} className='appbar__nav-link'>Resources</NavLink></div>
    <div style={{marginRight: '15px'}}><NavLink activeClassName='appbar__active' to={'/about'} className='appbar__nav-link'>About</NavLink></div>
    <div style={{marginRight: '15px'}}><NavLink activeClassName='appbar__active' to={'/sponsors'} className='appbar__nav-link'>Sponsors</NavLink></div>
    <div style={{marginRight: '15px'}}><NavLink activeClassName='appbar__active' to={'/join'} className='appbar__nav-link'>Join Us</NavLink></div>
  </Nav>

export default Appbar