import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'

import AuthUserContext from '../shared/Components/AuthUserContext'
import withAuthorization from '../HOCs/withAuthorization'
import NotFound from '../shared/Components/NotFound'


const PrivateRoutes = () => (

    <Switch>
      <Route exact path={'/ssp-admin/dashboard'} component={Dashboard} />

    </Switch>

)

const authCondition = (authUser) => !!authUser

export default withAuthorization(authCondition)(PrivateRoutes)
// export default PrivateRoutes