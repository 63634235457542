import React from 'react'

import './fullscreenLayout.css'


const FullscreenContentLayout = ({ children, className }) => (
  <div className='l-fullscreen-content' >
    {children}
  </div>
)

export default FullscreenContentLayout
