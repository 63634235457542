import React, { Component } from 'react'
import firebase from 'firebase/app'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  Alert
} from 'reactstrap'

import StarRatings from 'react-star-ratings'
import { db } from '../../firebase'
import {storage} from '../../firebase/firebase'
import Tabs from '../../shared/Components/Tabs'
import LocationSearchInput from '../LocationSearchInput'
import MapDataList from './MapDataList'
import Sponsors from '../Sponsors'
import AboutUs from '../AboutUs'
import Research from '../Research'
import JoinUsRequest from '../JoinUsRequest/JoinUsRequest'
import NewsAndInformation from '../NewsAndInformation'

const starReportStorageRef = storage.ref('starReports')


const INITIAL_STATE = {
  errorMessage:'',
  address: '',
  latitude: '',
  longitude: '',
  disproportionalityIndex: '',
  progressIndex: '',
  starReport: '',
  activeTab: '1'
}
class Dashboard extends Component {
  state = {
    ...INITIAL_STATE
  }


  componentDidMount() {

  }

  dropFile =(file, error) =>{
    if(error.length > 0) {
     if(error[0].size > 5242880) {
       this.setState({
         errorMessage: 'File size is above the 5MB max',
         selectedFile: '',
         selectedFileName: ''
       })
     }
    }
    else {
      this.setState({
        selectedFile: file[0],
        selectedFileName: file[0].name,
        errorMessage: ''
      })
    }
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getAddressData = (addressData) => {
    this.setState({
      address: addressData.address,
      latitude: addressData.latitude,
      longitude: addressData.longitude
    })
  }

  submitData = (e) => {

    const mapPinData = {
      address: this.state.address,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      disproportionalityIndex: this.state.disproportionalityIndex,
      progressIndex: this.state.progressIndex,
      // starReport: this.state.selectedFile,
    }
    if(this.state.selectedFile){
      mapPinData.starReport = this.state.selectedFile
    }

    const randomNumber = Math.random() // this is used to keep file names unique incase 2 different pdfs are uploaded with the same file name
    let uploadTask


    if(mapPinData.starReport){
      uploadTask = starReportStorageRef.child(`${randomNumber}__${mapPinData.starReport.name}`).put(mapPinData.starReport)

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        let progress = (snapshot.bytesTransferred) / (snapshot.totalBytes) * 100
        this.setState({progress: progress})
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            this.setState({
              errorMessage: 'Unauthorized'
            })
            break;
          case 'storage/canceled':
          this.setState({
            errorMessage: 'Cancelled'
          })
            break;
          case 'storage/unknown':
          this.setState({
            errorMessage: 'Unknown Error. Error code' + error.code
          })
            break;
          default:
            return null
        }
      }, ()=> {
        uploadTask.snapshot.ref.getMetadata().then( data => {
          uploadTask.snapshot.ref.getDownloadURL().then(
            downloadedURL => {
              mapPinData.fileName = data.name
              mapPinData.fileLink = downloadedURL

              db.doCreateMapData(mapPinData, () => this.setState({ ...INITIAL_STATE,
                fileName: '',
                selectedFile: '',
                selectedFileName: '',
                progress: 0
               }))
            }
          )
        })
      }
      )
    }
    else {
      db.doCreateMapData(mapPinData, () => this.setState({ ...INITIAL_STATE,
        fileName: '',
        selectedFile: '',
        selectedFileName: '',
        progress: 0
       }))
    }


  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }


  render() {
    return (
      <Container fluid>
        <h3 className='mt-2 mb-2 text-center' >Admin Dashboard</h3>
        <Nav tabs>
          <Tabs tabNumber='1' toggleTab={this.toggleTab} activeTab={this.state.activeTab}>
            Map
          </Tabs>
          <Tabs tabNumber='2' toggleTab={this.toggleTab} activeTab={this.state.activeTab} >
            Sponsors
          </Tabs>
          <Tabs tabNumber='3' toggleTab={this.toggleTab} activeTab={this.state.activeTab}>
            STARS Reports
          </Tabs>
          <Tabs tabNumber='4' toggleTab={this.toggleTab} activeTab={this.state.activeTab}>
            About Us/Mission Statement
          </Tabs>
          {/* <Tabs tabNumber='5' toggleTab={this.toggleTab} activeTab={this.state.activeTab}>
            Contact/Social Media
          </Tabs> */}
          <Tabs tabNumber='6' toggleTab={this.toggleTab} activeTab={this.state.activeTab}>
            Join Us Requests
          </Tabs>
          {/* <Tabs tabNumber='7' toggleTab={this.toggleTab} activeTab={this.state.activeTab}>
            News &amp; Info
          </Tabs> */}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId='1'>
            <Row className='mt-2 mb-2'>
              <Col>
                <h1>Map Pins</h1>
                <p>Here you can add or delete map pins on the home page</p>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6}>
                <Card>
                  <CardBody>
                  {this.state.errorMessage &&
                    <Alert color="danger">
                    {this.state.errorMessage}
                    </Alert>
                  }
                    <CardTitle>Add Pin To Map</CardTitle>
                    <Form>
                      <FormGroup>
                        <Label>Location</Label>
                        <small style={{display: 'block'}}><strong>Please select location from list that appears when typing. Otherwise, pin may not be set properly.</strong></small>
                        <LocationSearchInput
                          getAddress={this.getAddressData}
                          setClick={click => this.clickChild = click}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Disproportionality Index</Label>
                        <Input type='string' name='disproportionalityIndex' onChange={this.onChange} value={this.state.disproportionalityIndex} />
                        <StarRatings
                          rating={parseFloat(this.state.disproportionalityIndex) || 0}
                          starRatedColor='gold'
                          numberOfStars={5}
                          name='disproportionalityIndex'
                          starDimension='20px'
                          starSpacing='7px'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Progress Index</Label>
                        <Input type='string' name='progressIndex' onChange={this.onChange} value={this.state.progressIndex} />
                        <StarRatings
                          rating={parseFloat(this.state.progressIndex) || 0}
                          starRatedColor='gold'
                          numberOfStars={5}
                          name='progressIndex'
                          starDimension='20px'
                          starSpacing='7px'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>STAR Report</Label>
                        <Dropzone
                          accept='application/pdf'
                          onDrop={this.dropFile}
                          onFileDialogCancel={() =>
                            this.setState({
                              selectedFile: '',
                              selectedFileName: '',
                              errorMessage: ''
                            })}
                          maxSize={5000000}
                          disableClick
                        >
                          {({getRootProps, getInputProps, open}) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                                <button type="button" onClick={() => open()}>
                                  Select File to Upload
                                </button>
                                <span> {this.state.selectedFileName}</span>
                            </div>
                          )}
                        </Dropzone>
                        <Label>
                          <small style={{display: 'block'}}>File must not exceed 5MB and must be file type PDF</small>
                        </Label>
                      </FormGroup>
                      <Button
                        onClick={() => {
                          this.submitData()
                          this.clickChild() //resets the locationSearchInput
                        }}
                        disabled={this.state.address === ''}
                      >
                        Submit
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Map Data List</CardTitle>
                    <MapDataList />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId='2'>
            <Sponsors />
          </TabPane>
          <TabPane tabId='3'>
            <Research />
          </TabPane>
          <TabPane tabId='4'>
            <AboutUs />
          </TabPane>
          {/* <TabPane tabId='5'>
            <ContactInfo />
          </TabPane> */}
          <TabPane tabId='6'>
            <JoinUsRequest />
          </TabPane>
          {/* <TabPane tabId='7'>
            <NewsAndInformation />
          </TabPane> */}

        </TabContent>
      </Container>
    )
  }
}

export default Dashboard
