import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Label,
  Card,
  CardBody,
  CardTitle,
  Alert
} from 'reactstrap'
import { storage } from '../../firebase/firebase'
import { db } from '../../firebase/firebase'
import firebase from 'firebase/app'
import ResearchList from './ResearchList'

const storageRef = storage.ref('researchReports')

////////////////////////
// HTTP String helper //
////////////////////////
function httpStringHelper(url) {
  if(url === '')
    return ''

  if(url.indexOf("http://") === 0 || url.indexOf('https://') === 0){

    return url
  } else {
    return `http://${url}`
  }

}

//////////////
// Component //
//////////////

class Research extends Component {
  state={
    errorMessage:'',
    researchName: '',
    researchDescription: '',
    researchWebsite: '',
    selectedFile: null,
    uploadProgress: null,
    fileName: '',
    isSubmitting: false
  }

  fileSelectedHandler = event => {
    if(event.target.files[0].type !== "application/pdf"){
      this.setState({
        selectedFile: null,
        errorMessage: 'File Type Must Be In PDF Format'
      })
    }
    else if(event.target.files[0].size > 5000000) {
      this.setState({
        selectedFile: null,
        errorMessage: 'Selected file is bigger than 5MB. Please select a smaller file.'
      })
    }  else {
      this.setState({
        errorMessage: null,
        selectedFile: event.target.files[0],
        fileName: event.target.files[0].name
      })
    }
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submit = () => {
    this.setState({
      isSubmitting: true
    })

    if (this.state.selectedFile == null) {
      db.ref('researchData').push({
        researchName: this.state.researchName,
        researchDescription: this.state.researchDescription,
        researchWebsite: httpStringHelper(this.state.researchWebsite)
      }).then(()=>{
        this.setState({
          errorMessage:'',
          researchName: '',
          researchDescription: '',
          researchWebsite: '',
          selectedFile: null,
          uploadProgress: null,
          fileName: '',
          isSubmitting: false
        })
      })
      .catch(err => this.setState({errorMessage: 'Fatal Error: ' + err}))
    }
    else {
      const uploadTask = storageRef.child(`${this.state.researchName}__${this.state.selectedFile.name}`).put(this.state.selectedFile)
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      function(snapshot){
        let progress = (snapshot.bytesTransferred) / (snapshot.totalBytes) * 100
        this.setState=({
          uploadProgress: progress
        })
      },
      function(error){
        switch (error.code) {
          case 'storage/unauthorized':
            this.setState({
              errorMessage: 'Unauthorized'
            })
            break;
          case 'storage/canceled':
          this.setState({
            errorMessage: 'Cancelled'
          })
            break;
          case 'storage/unknown':
          this.setState({
            errorMessage: 'Unknown Error. Error code' + error.code
          })
            break;
          default:
            return null
        }
      }, ()=> {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
          const researchData = {
            researchName: this.state.researchName,
            researchDescription: this.state.researchDescription,
            researchWebsite: httpStringHelper(this.state.researchWebsite),
            fileName: this.state.fileName,
            researchReport: downloadURL
          }
          if(researchData.fileName === ''){
            delete researchData.fileName
          }

          db.ref('researchData').push(researchData).then(() => {
            this.setState({
              errorMessage:'',
              researchName: '',
              researchDescription: '',
              researchWebsite: '',
              fileName: '',
              selectedFile: null,
              uploadProgress: null,
              isSubmitting: false
            })
          }, err => {
            this.setState({
              errorMessage:err,
              researchName: '',
              researchDescription: '',
              researchWebsite: '',
              fileName: '',
              selectedFile: null,
              uploadProgress: null,
              isSubmitting: false
            })
          })
        })
      })
    }




  }

  render() {
    return (
      <Container fluid className='mt-3'>
       {this.state.errorMessage &&
        <Alert color="danger">
         {this.state.errorMessage}
        </Alert>
      }
        <Row className='mt-2 mb-3'>
          <Col>
            <h1>STARS Reports</h1>
            <p>Here you can add or delete research on the STARS reports page</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardTitle>
                  Add Research
                </CardTitle>
                <Form>
                  <FormGroup>
                    <Label>
                      Research Report Name
                    </Label>
                    <Input
                      type='text'
                      name='researchName'
                      onChange={this.onChange}
                      value={this.state.researchName}
                      />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Research Report Description (If applicable)
                    </Label>
                    <Input
                      type='textarea'
                      name='researchDescription'
                      onChange={this.onChange}
                      value={this.state.researchDescription}
                      />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Research Report Website (If applicable)
                    </Label>
                    <Input
                      type='input'
                      name='researchWebsite'
                      onChange={this.onChange}
                      value={this.state.researchWebsite}
                      />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Research PDF (If applicable)
                      <small style={{display: 'block'}}>File must not exceed 5MB and must be file type PDF</small>
                    </Label>
                    <Input
                      type='file'
                      onChange={this.fileSelectedHandler}
                    ></Input>
                    {this.state.uploadProgress &&
                      <span>{Math.floor(this.state.uploadProgress)}%</span>
                    }
                  </FormGroup>

                  <Button
                    disabled={this.state.researchName === '' || this.state.errorMessage || this.state.isSubmitting}
                    onClick={this.submit}
                    >
                    {this.state.isSubmitting ? 'Uploading' : 'Submit'}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <ResearchList  />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Research
