import {db, storage} from './firebase'

export const doCreateMapData = (schoolData, cb) => {
  db.ref(`mapData`).push({
    schoolData
  }, function(error){
    if(!error){
      cb()
    }
  })
}

export const getMapData = () => {
  return new Promise ( (resolve, reject) => {
    db.ref(`mapData`).on('value', (snapshot) =>{
      resolve(snapshot)
  })
  })

}

export const deleteMapData = (key, fileName) => {
  if(fileName){
    storage.ref(`starReports/${fileName}`).delete()
  }
  return db.ref(`mapData/${key}`).remove()
}

export const deleteSponsor = (key) => {
  return db.ref(`sponsorData/${key}`).remove()
}

export const deleteResearch = (key, fileName) => {
  if(!fileName.includes('__undefined')){
    storage.ref(`researchReports/${fileName}`).delete()
  }
  db.ref(`researchData/${key}`).remove()
}

export const createJoinUs = (contact) => {
    return new Promise( (resolve, reject) => {
      db.ref('joinUsContacts').push(contact, (error) => {
        if(error) reject(error)
        else resolve("Success")
      })
    })
}



export const markJoinUsViewed = (key) => {
  return new Promise ((resolve, reject) => {
    const joinUsRef = db.ref(`joinUsContacts/${key}`)
    const readRef = db.ref('readJoinUsContacts')

    joinUsRef.once('value')
      .then(snapshot => {
        readRef.push(snapshot.val(), (error)=>{
          if(!error) {
            joinUsRef.remove()
            resolve()
          } else {
            reject(error)
          }
        })
      })
  })
}