import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormText
} from 'reactstrap'
import { db as firebaseDb } from '../../firebase/firebase';

class AboutUs extends Component {

  state={
    mainPageText: '',
    about: '',
    mission: ''
  }

  componentDidMount() {
    firebaseDb.ref('aboutUs').on('value', snapshot => {
      const aboutUsData = snapshot.val()
      this.setState({
        mainPageText: aboutUsData.mainPageText,
        about: aboutUsData.about,
        mission: aboutUsData.mission
      })
    })
  }

  submit = (section, data) => {
    firebaseDb.ref(`aboutUs/${section}`).set(data)
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <Container fluid className='mt-3'>
        <Row className='mt-2 mb-2'>
          <Col>
            <h1>Edit Main Page, About Us Page and Mission Statement</h1>
            <p>Changes made here will be reflected on the Main Page, About Us page and Mission statement on home page</p>
          </Col>
        </Row>
        <Form className='mt-2 mb-3'>
          <Row>
            <Col>
                <FormGroup className='mb-3'>
                  <Label><strong>Main Page Text</strong></Label>
                  <Input
                    type='textarea'
                    rows={8}
                    name='mainPageText'
                    onChange={this.onChange}
                    value={this.state.mainPageText}
                    />
                    <Button
                      className='mt-3 float-right'
                      size='sm'
                      color='success'

                      onClick={() => this.submit('mainPageText', this.state.mainPageText)}>
                      Save
                    </Button>
                </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
                <FormGroup className='mb-3'>
                  <Label><strong>About School Success</strong></Label>
                  <Input
                    type='textarea'
                    rows={8}
                    name='about'
                    onChange={this.onChange}
                    value={this.state.about}
                    />
                    <Button
                      className='mt-3 float-right'
                      size='sm'
                      color='success'

                      onClick={() => this.submit('about', this.state.about)}>
                      Save
                    </Button>
                </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
                <FormGroup className='mb-3'>
                  <Label><strong>Mission Statement</strong></Label>
                  <FormText>Mission statement is shown on the home page</FormText>
                  <Input
                    type='textarea'
                    rows={8}
                    name='mission'
                    onChange={this.onChange}
                    value={this.state.mission}
                    />
                    <Button
                      className='mt-3 float-right'
                      size='sm'
                      color='success'

                      onClick={() => this.submit('mission', this.state.mission)}>
                      Save
                    </Button>
                </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

export default AboutUs