import React, { Component } from 'react'
import {Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Button,
  CardSubtitle,
  Alert
} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import { auth } from '../../firebase'
import FullscreenLayout from '../../shared/Components/FullscreenLayout'


const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class Login extends Component {

  state ={
    ...INITIAL_STATE
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;


    auth.doSignInWithEmailAndPassword(email, password)
      .then((e) => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/ssp-admin/dashboard');
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render(){
    const { error, email, password, isInvalid } = this.props
    return (
      <FullscreenLayout>
        <Container >
          <Row>
            <Col md={6} className='mx-auto'>
              <Card >
                <CardBody>
                  {this.state.error &&
                    <Alert color='danger'>{this.state.error.code}</Alert>
                  }
                  <CardSubtitle className="mb-3">Admin Login</CardSubtitle>
                  <Form onSubmit={this.onSubmit}>
                    <Input
                      value={email}
                      onChange={event => this.setState(byPropKey('email', event.target.value))}
                      type="text"
                      placeholder="Email Address"
                      className="mb-3"
                    />
                    <Input
                      value={password}
                      onChange={event => this.setState(byPropKey('password', event.target.value))}
                      type="password"
                      placeholder="Password"
                      className="mb-3"
                    />
                    <Button disabled={isInvalid} type="submit">
                      Sign In
                    </Button>

                    { error && <p>{error.message}</p> }
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FullscreenLayout>


    )
  }
}
export default withRouter(Login)