import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap'
import { db as firebaseDb } from '../../firebase/firebase'
import ImageModal from '../../shared/Components/ImageModal'
import infoGraphic from '../../shared/images/info-graphic.jpg'
import './about.css'
class About extends Component {
  state ={}

  componentDidMount() {
    firebaseDb.ref('aboutUs').once('value', snapshot => {
      const aboutUsData = snapshot.val()
      this.setState({
        about: aboutUsData.about,
      })
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {

    if(this.state === {}) return <h2>It does not look like anything is here.</h2>

    return (
      <Container fluid>
        {this.state.about !== '' &&
        <React.Fragment>
          <Row className='mt-3 mb-3'>
            <Col md={5}>
              <h1>School Success Project Goals and Objectives</h1>
              {/* <p>{this.state.about}</p> */}
              <p>Goal 1: Eliminate disproportionality of school suspension and expulsion for all minority groups.</p>
              <p>Objective #1: Collaborate and capacity-build with school districts, academic institutions, existing and new advocacy groups and organizations, individuals, and communities.</p>
              <p>Objective #2: Make use of existing evidence-based and innovative methods.</p>
              <p>Objective #3: Gather data to complete our interactive map.</p>
              <p>Objective #4: Galvanize interest at local, state, and national levels to actively participate in the School Success Project.</p>
              <p>Objective #5: By the year 2025 we will significantly reduce disproportionality in school suspensions and expulsions.</p>
            </Col>
            <Col md={7}>
              <img
                className='img-fluid about__graphic'
                src={infoGraphic}
                alt='Minor infractions school bus graphic'
                onClick={this.toggle}
                style={{boxShadow: '0 4px 6px 0 hsla(0,0%,0%, 0.2)'}}
              />
            </Col>
          </Row>
          <ImageModal
            image={infoGraphic}
            altText='Minor infractions school bus graphic'
            isOpen={this.state.modal}
            toggle={this.toggle}
          />

        </React.Fragment>
        }

      </Container>
    )
  }
}

export default About
