import React, { PureComponent } from 'react';
import StarRatings from 'react-star-ratings'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'


export default class CityInfo extends PureComponent {
  state = {
    popoverOpen1: false,
    popoverOpen2: false
  }

  toggle1 = () => {
    this.setState({
      popoverOpen1: !this.state.popoverOpen1
    })
  }
  toggle2 = () => {
    this.setState({
      popoverOpen2: !this.state.popoverOpen2
    })
  }
  render() {
    const { info } = this.props;
    const displayName = `${info.address}`;

    return (
      <div>
        <div >
          <strong style={{ display: 'block' }} className='mb-2'>{displayName}</strong>
          {info.fileLink &&
            <div>
              <a href={info.fileLink} id={info.fileName} target='_blank' rel="noopener noreferrer">View STAR Report</a>
            </div>
          }
          <span style={{ display: 'block' }} ><small><strong>Disproportionality Index:</strong></small></span>
          <div style={{ display: 'inline' }} onMouseEnter={this.toggle2} onMouseLeave={this.toggle2} id='Popover2' className='mb-2'>
            <StarRatings
              rating={parseFloat(info.disproportionalityIndex) || 0}
              starRatedColor='gold'
              numberOfStars={5}
              name='disproportionalityIndex'
              starDimension='20px'
              starSpacing='5px'
            />
          </div>
          <span style={{ display: 'block' }} ><small><strong>Progress Index:</strong></small></span>
          <div style={{ display: 'inline' }} onMouseEnter={this.toggle1} onMouseLeave={this.toggle1} id='Popover1'>
            <StarRatings
              rating={parseFloat(info.progressIndex) || 0}
              starRatedColor='gold'
              numberOfStars={5}
              name='progressIndex'
              starDimension='20px'
              starSpacing='5px'
            />
          </div>

          {/* <Popover placement="top" isOpen={this.state.popoverOpen1} target="Popover1" toggle={this.toggle1}>
            <PopoverBody>{info.progressIndex}</PopoverBody>
          </Popover>
          <Popover placement="top" isOpen={this.state.popoverOpen2} target="Popover2" toggle={this.toggle2}>
            <PopoverBody>{info.disproportionalityIndex}</PopoverBody>
          </Popover> */}
        </div>
      </div>
    );
  }
}