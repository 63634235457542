import React from 'react'
import { Input, Button, Label, FormGroup, Col } from 'reactstrap'

const BootstrapInput = ({
  name, inputType, value, onChange, label, placeholder,id, ...props
}) => {
  return(
    <FormGroup>
      <Label for={name}>{label}</Label>

        <Input
          type={inputType}
          id={name || id}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...props}
          />

    </FormGroup>
  )
}

export default BootstrapInput