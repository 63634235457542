import React, {Component} from 'react'
import {Table, Button} from 'reactstrap'
import { db as firebaseDb } from '../../firebase/firebase';
import { db } from '../../firebase'
class SponsorList extends Component {
  state = {
    sponsorData: [],
    alert: null
  }
  componentDidMount(){
    firebaseDb.ref('sponsorData').on('value', snapshot => {
      const sponsorData = snapshot.val()
      const sponsorDataArr = []
      for(var data in sponsorData){
        sponsorDataArr.push({[data]: sponsorData[data]})
      }
      this.setState({
        sponsorData: sponsorDataArr
      })
    })
  }


  removeSponsor = (key) =>{
    db.deleteSponsor(key)
  }

  render(){
    return(
      <div style={{maxHeight: '560px', overflow: 'auto'}}>
         <Table>
        <thead>
          <tr>
            <th></th>
            <th>Sponsor Name</th>
            <th>Website</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.sponsorData.map( (data, i)=>{
            const dataKey = data[Object.keys(data)]
            return(
              <tr key={i} id={Object.keys(data)}>
                <td>
                  {dataKey.sponsorLogo != null &&
                  <div >
                    <img style={{width: '50px'}} src={dataKey.sponsorLogo} alt={dataKey.sponsorName} />
                  </div>
                  }

                </td>
                <td>
                  <strong>{dataKey.sponsorName}</strong>
                  <span style={{display: 'block'}}>{dataKey.sponsorDescription}</span>
                </td>
                <td><a target='_blank' rel="noopener noreferrer" href={dataKey.sponsorWebsiteLink}>View Site</a></td>
                <td>
                  <Button
                    color='link'
                    onClick={() =>this.removeSponsor(Object.keys(data)[0])}
                    >
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>

    )
  }
}

export default SponsorList